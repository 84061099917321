import React from 'react';
import './Header.css'; // นำเข้าไฟล์ CSS

const Header = () => {
  return (
    <header>
      <nav>
        <ul>
          {/* ลบลิงก์ออก */}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
