import React from 'react';
import { FaSpa, FaHeart, FaUserMd, FaHandsHelping, FaRegSun } from 'react-icons/fa';

const MedicalSpa = () => {
  // สร้างรายการหมวดหมู่สินค้า
  const categories = [
    { id: 1, icon: <FaSpa />, text: 'การบำบัดด้วยสปา', color: '#FF5733' }, // สีส้ม
    { id: 2, icon: <FaHeart />, text: 'การดูแลสุขภาพ', color: '#33FF57' }, // สีเขียว
    { id: 3, icon: <FaUserMd />, text: 'การรักษาแพทย์', color: '#3357FF' }, // สีน้ำเงิน
    { id: 4, icon: <FaHandsHelping />, text: 'การฟื้นฟูสุขภาพ', color: '#FFC300' }, // สีเหลือง
    { id: 5, icon: <FaRegSun />, text: 'การบำบัดแสง', color: '#DAF7A6' }, // สีเขียวอ่อน
  ];

  return (
    <div className="container">
      <h1>หมวดหมู่สินค้า Medical</h1>
      <div style={{justifyContent: 'space-around', alignItems: 'center', flexWrap: 'wrap' }}>
        {categories.map(category => (
          <div
            key={category.id}
            style={{
              backgroundColor: category.color,
              borderRadius: '8px',
              padding: '20px',
              margin: '10px',
              flex: '1 0 18%', // ทำให้กล่องมีขนาดที่กำหนด
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              minHeight: '100px' // ตั้งค่าความสูงขั้นต่ำ
            }}
          >
            <span style={{ marginRight: '10px' }}>{category.icon}</span>
            <span>{category.text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MedicalSpa;
