import React, { useState, useEffect } from "react";
import MenubarAdmin from "../../../layouts/MenubarAdmin";
import { createCategory, listCategory, deleteCategory } from "../../../function/MunuItem";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FileUpload from "./FileUpload"; // นำเข้า FileUpload component

const CreateMenu = () => {
    const { user } = useSelector((state) => ({ ...state }));
    const [values, setValues] = useState({
        name: "",
        subCategories: [{
            name: "",
            websiteLink: "",
            products: [{
                name: "",
                images: [],
                description: "",
                price: "",
                discountedPrice: "",
                sold: 0,
                quantity: 0,
            }]
        }],
    });

    const [category, setCategory] = useState([]);

    useEffect(() => {
        loadData(user.token);
    }, []);

    const loadData = (authtoken) => {
        listCategory(authtoken)
            .then((res) => {
                setCategory(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleRemove = (id) => {
        deleteCategory(user.token, id)
            .then((res) => {
                console.log(res);
                loadData(user.token);
                toast.success('ลบ ' + res.data.name + ' เรียบร้อย');
            })
            .catch((err) => {
                console.log(err);
                toast.error('Error ! ');
            });
    };

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubCategoryChange = (index, e) => {
        const newSubCategories = [...values.subCategories];
        newSubCategories[index].name = e.target.value; // อัปเดตชื่อรายการย่อย
        setValues({ ...values, subCategories: newSubCategories });
    };

    const handleWebsiteLinkChange = (index, e) => {
        const newSubCategories = [...values.subCategories];
        newSubCategories[index].websiteLink = e.target.value; // อัปเดตลิงก์เว็บไซต์
        setValues({ ...values, subCategories: newSubCategories });
    };

    const handleProductChange = (subCategoryIndex, productIndex, e) => {
        const newSubCategories = [...values.subCategories];
        newSubCategories[subCategoryIndex].products[productIndex][e.target.name] = e.target.value; // อัปเดตชื่อผลิตภัณฑ์
        setValues({ ...values, subCategories: newSubCategories });
    };

    const handleAddSubCategory = () => {
        setValues({ ...values, subCategories: [...values.subCategories, { name: "", websiteLink: "", products: [{ name: "", images: [], description: "", price: "", discountedPrice: "", sold: 0, quantity: 0 }] }] }); // เพิ่มรายการย่อยใหม่
    };

    const handleAddProduct = (subCategoryIndex) => {
        const newSubCategories = [...values.subCategories];
        newSubCategories[subCategoryIndex].products.push({ name: "", images: [], description: "", price: "", discountedPrice: "", sold: 0, quantity: 0 }); // เพิ่มผลิตภัณฑ์ใหม่ในรายการย่อย
        setValues({ ...values, subCategories: newSubCategories });
    };

    const handleSummit = (e) => {
        e.preventDefault();
        if (!values.name.trim()) {
            toast.error('กรุณากรอกชื่อหมวดหมู่สินค้า');
            return;
        }
    
        console.log("Submitting values:", values); // ตรวจสอบค่าที่จะส่งไป
        createCategory(user.token, values)
            .then((res) => {
                console.log(res);
                loadData(user.token);
                // Reset input fields correctly
                setValues({
                    name: "",
                    subCategories: [{ 
                        name: "", 
                        websiteLink: "", 
                        products: [{ 
                            name: "", 
                            images: [], 
                            description: "", 
                            price: "", 
                            discountedPrice: "", 
                            sold: 0, 
                            quantity: 0 
                        }] 
                    }], // ตรวจสอบว่าใช้วงเล็บปีกกาและวงเล็บเหลี่ยมอย่างถูกต้อง
                });
                toast.success('เพิ่ม ' + res.data.name + ' เรียบร้อย');
            })
            .catch((err) => {
                console.error("Error creating category:", err);
                toast.error('Error !');
            });
    };
    
    

    return (
        <div>
            <div className="row">
                <div className="col-md-2">
                    <MenubarAdmin />
                </div>
                <div className="col-md-8">
                    <h1>Create Category Brand</h1>
                    <form onSubmit={handleSummit}>
                        <div className="form-group">
                            <label>เพิ่มหมวดหมู่สินค้า</label>
                            <input
                                type="text"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                        <h4>เพิ่มรายการย่อย</h4>
                        {values.subCategories.map((subCategory, subCategoryIndex) => (
                            <div key={subCategoryIndex}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name={`subCategory${subCategoryIndex}`}
                                        value={subCategory.name}
                                        onChange={(e) => handleSubCategoryChange(subCategoryIndex, e)}
                                        className="form-control"
                                        placeholder="ชื่อรายการย่อย"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name={`websiteLink${subCategoryIndex}`}
                                        value={subCategory.websiteLink}
                                        onChange={(e) => handleWebsiteLinkChange(subCategoryIndex, e)}
                                        className="form-control"
                                        placeholder="ลิงก์เว็บไซต์"
                                    />
                                </div>
                                <h5>ผลิตภัณฑ์ในรายการย่อย</h5>
                                {subCategory.products.map((product, productIndex) => (
                                    <div key={productIndex}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="name"
                                                value={product.name}
                                                onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                                className="form-control"
                                                placeholder="ชื่อผลิตภัณฑ์"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="description"
                                                value={product.description}
                                                onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                                className="form-control"
                                                placeholder="คำอธิบาย"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                name="price"
                                                value={product.price}
                                                onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                                className="form-control"
                                                placeholder="ราคา"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                name="discountedPrice"
                                                value={product.discountedPrice}
                                                onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                                className="form-control"
                                                placeholder="ราคาลด"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                name="sold"
                                                value={product.sold}
                                                onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                                className="form-control"
                                                placeholder="จำนวนที่ขายไป"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={product.quantity}
                                                onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                                className="form-control"
                                                placeholder="จำนวนสินค้า"
                                            />
                                        </div>
                                        {/* ใช้ FileUpload component สำหรับการอัปโหลดรูปภาพ */}
                                        <FileUpload
                                            values={values}
                                            setValues={setValues}
                                            loading={false}
                                            setLoading={() => {}}
                                            subIndex={subCategoryIndex}
                                            productIndex={productIndex}
                                        />
                                    </div>
                                ))}
                                <button type="button" onClick={() => handleAddProduct(subCategoryIndex)} className="btn btn-outline-secondary">
                                    เพิ่มผลิตภัณฑ์
                                </button>
                            </div>
                        ))}
                        <button type="button" onClick={handleAddSubCategory} className="btn btn-outline-secondary">
                            เพิ่มรายการย่อย
                        </button>
                        <button className="btn btn-outline-primary">เพิ่ม</button>
                    </form>
                    <hr />
                    <ul className="list-group">
                        {category.map((item) => (
                            <li className="list-group-item" key={item._id}>
                                {item.name}
                                <span
                                    style={{ float: "right" }}
                                    className="badge bg-primary rounded-pill"
                                    onClick={() => handleRemove(item._id)}
                                >
                                    x
                                </span>
                                <span
                                    style={{ float: "right" }}
                                    className="badge bg-primary rounded-pill"
                                >
                                    <Link to={`/admin/update-category-menu/${item._id}`}>Edit</Link>
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CreateMenu;
