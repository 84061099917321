import React from 'react'
import MenubarUser from '../../layouts/MenubarUser'


const Home = () => {
  return (
    <div>
    <div className="row">
      <div className="col-md-2">
        <MenubarUser />
      </div>
      <div className="col">
        
        <div className="row">
          <h4>Home User</h4>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Home
