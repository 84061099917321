import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { EyeOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { listPromotions } from "../function/promotion";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Product.css';

const { Meta } = Card;

const ProductCardHome = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate
  const [isHovered, setIsHovered] = useState(false);
  const [hasPromotion, setHasPromotion] = useState(false);

  const { _id, title, description, images, price } = product;

  useEffect(() => {
    const fetchPromotions = async () => {
      try {
        const res = await listPromotions();
        const promotions = res.data;
        const productPromotion = promotions.find(promotion =>
          promotion.products.some(p => p._id === product._id)
        );

        if (productPromotion) {
          setHasPromotion(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPromotions();
  }, [product]);

  const handleAddToCart = (event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up to the Card
    let cart = [];
    if (localStorage.getItem('cart')) {
      cart = JSON.parse(localStorage.getItem('cart'));
    }
    cart.push({
      ...product,
      count: 1
    });

    let unique = _.uniqWith(cart, _.isEqual);
    localStorage.setItem('cart', JSON.stringify(unique));
    dispatch({
      type: 'ADD_TO_CART',
      payload: unique
    });
    dispatch({
      type: 'SET_VISIBLE',
      payload: true
    });
  };

  const shortenedDescription = description.slice(0, 15);

  // Handle navigation to SingleProductCard
  const handleCardClick = () => {
    navigate(`/product/${_id}`); // Navigate to SingleProductCard with product ID
  };

  // Format the price for Thai currency
  const formattedPrice = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB',
  }).format(price);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

const priceStyle = {
  fontSize: windowWidth <= 576 ? '1rem' : '1.5rem', // เปลี่ยนขนาด font ตามขนาดหน้าจอ
};

return (
  <div className='product-card-container' style={{ width: '150px' }}> {/* ทำให้การ์ดใช้ความกว้างทั้งหมด */}
    {hasPromotion && <div className='promotion-label'>Promotion</div>}
    <Card
      hoverable
      className='product-card mx-auto'
      cover={
        <img
          className='p-1'
          style={{ height: windowWidth <= 576 ? '200px' : '240px', width: '100%' }}
          alt='example'
          src={images && images.length ? images[0].url : ''}
        />
      }
      actions={[
        <EyeOutlined className='text-warning' onClick={handleCardClick} />, // Navigate on click
        <ShoppingCartOutlined onClick={handleAddToCart} className='text-danger' /> // Add to cart
      ]}
      style={{
        boxShadow: isHovered ? '0 4px 8px rgba(150, 27, 78, 0.7)' : '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease-in-out'
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleCardClick} // Click the card to navigate
    >
      <Meta title={title} description={windowWidth > 576 ? shortenedDescription : null} /> {/* แสดง description เฉพาะเมื่อหน้าจอใหญ่กว่า 576px */}
      <div className="product-price">
        <h4 style={priceStyle}>{formattedPrice}</h4>
      </div>
    </Card>
  </div>
);
};

export default ProductCardHome;
