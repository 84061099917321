import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // นำเข้า CSS ของ Bootstrap

const Rehabilitation = () => {
  const [key, setKey] = useState('clinic'); // ตั้งค่าแท็บเริ่มต้น

  return (
    <div className='container'>
      <h1>หมวดหมู่สินค้า</h1>
      <div style={{ display: 'flex' }}>
        <div style={{ flexShrink: 0 }}>
          <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 flex-column">
            <Tab eventKey="clinic" title="คลินิคกายภาพบำบัด">
              {/* แสดงข้อมูลสำหรับคลินิคกายภาพบำบัดที่นี่ */}
              <p>รายละเอียดเกี่ยวกับคลินิคกายภาพบำบัด...</p>
            </Tab>
            <Tab eventKey="elderCare" title="ศูนย์ผู้ดูแลผู้สูงอายุ">
              {/* แสดงข้อมูลสำหรับศูนย์ผู้ดูแลผู้สูงอายุที่นี่ */}
              <p>รายละเอียดเกี่ยวกับศูนย์ผู้ดูแลผู้สูงอายุ...</p>
            </Tab>
          </Tabs>
        </div>
        <div style={{ marginLeft: '20px', flexGrow: 1 }}>
          {/* เนื้อหาของแท็บที่เลือกจะแสดงที่นี่ */}
          {key === 'clinic' ? (
            <div>
              <h2>คลินิคกายภาพบำบัด</h2>
              <p>รายละเอียดเกี่ยวกับคลินิคกายภาพบำบัด...</p>
            </div>
          ) : (
            <div>
              <h2>ศูนย์ผู้ดูแลผู้สูงอายุ</h2>
              <p>รายละเอียดเกี่ยวกับศูนย์ผู้ดูแลผู้สูงอายุ...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Rehabilitation;
