// src/components/MenubarUserHomeNew.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaUser, FaSpa, FaBox, FaCut, FaClock, FaHotel } from "react-icons/fa";
import { listCategory } from "../function/MunuItem"; // เพิ่มการนำเข้า listCategory
import "./MenubarUserHomeNew.css";

const MenubarUserHomeNew = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = () => {
    // ดึงข้อมูลหมวดหมู่จาก API
    listCategory() // แก้ไขให้ตรงตามฟังก์ชันในฟังก์ชัน
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.error("Error loading categories:", err);
      });
  };

  return (
    <nav className="navUserHome">
      <ul className="nav row">
       {categories.map((category) => (
          <li key={category._id} className="nav-item col-6">
            <Link
              to={`/aesthetics/${category._id}`}
              className={category.name.toLowerCase()}
            >
              <FaUser className="icon" /> {category.name}
            </Link>
          </li>
        ))} 
        {/* เพิ่มรายการเมนู Products แยกต่างหาก */}
        <li className="nav-item col-6">
          <Link to="/product-menu-userhome" className="products">
            <FaBox className="icon" /> Products
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MenubarUserHomeNew;
