import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Partner.css'; // นำเข้าไฟล์ CSS ของคุณ

const Partner = () => {
  const [key, setKey] = useState('conditions'); // ตั้งค่าแท็บเริ่มต้น

  return (
    <div className='container'>
      <h2 className='text-center'>ข้อมูล Partner</h2>
      <div className=" justify-content-center mb-3">
        <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="text-center">
          <Tab eventKey="conditions" title="เงื่อนไขการเป็น Partner" className="tab-background">
            <p>เนื้อหาสำหรับเงื่อนไขการเป็น Partner</p>
          </Tab>
          <Tab eventKey="steps" title="ขั้นตอน" className="tab-background">
            <p>เนื้อหาสำหรับขั้นตอนการเป็น Partner</p>
          </Tab>
          <Tab eventKey="news" title="ข่าวสาร Partner" className="tab-background">
            <p>เนื้อหาสำหรับข่าวสาร Partner</p>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Partner;
