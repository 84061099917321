import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getCategory } from "../../function/MunuItem";
import './SubCategoryList.css';

const SubCategoryList = () => {
    const { id } = useParams();
    const [subCategories, setSubCategories] = useState([]);
    const [categoryName, setCategoryName] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadSubCategories();
    }, [id]);

    const loadSubCategories = async () => {
        try {
            const token = localStorage.token;
            const res = await getCategory(token, id);
            setCategoryName(res.data.name);
            setSubCategories(res.data.subCategories);
            setLoading(false);
        } catch (err) {
            console.error("Error loading subcategories:", err);
            setLoading(false);
        }
    };

    if (loading) return <div>Loading...</div>;

    return (
        <div className="sub-category-container">
            <h2 className="category-title">{categoryName}</h2>
            <ul className="sub-category-list">
                {subCategories.map((sub) => (
                    <li key={sub._id} className="sub-category-item">
                        <Link to={`/product-menu/${sub._id}`}>{sub.name}</Link> {/* Navigate to ProductDetails with subCategory ID */}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SubCategoryList;
