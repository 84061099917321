import React from "react";
import Resizer from "react-image-file-resizer";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { Avatar, Badge } from "antd";

const FileUpload = ({ values, setValues, loading, setLoading, subIndex, productIndex }) => {
    const { user } = useSelector((state) => ({ ...state }));

    const handleChangeFile = (e) => {
        const files = e.target.files;
        if (files) {
            setLoading(true);
            let allFileUpload = values.subCategories[subIndex].products; // get products array
            console.log("Uploading to:", process.env.REACT_APP_API + "/images");
            console.log("Using token:", user.token); // Debug token
            
            for (let i = 0; i < files.length; i++) {
                Resizer.imageFileResizer(
                    files[i],
                    300,
                    300,
                    "JPEG",
                    100,
                    0,
                    (uri) => {
                        axios
                            .post(
                                process.env.REACT_APP_API + "/images",
                                { image: uri },
                                { headers: { authtoken: user.token } }
                            )
                            .then((res) => {
                                setLoading(false);
                                allFileUpload[productIndex].images.push(res.data.secure_url);
                                setValues({
                                    ...values,
                                    subCategories: [
                                        ...values.subCategories.slice(0, subIndex),
                                        { ...values.subCategories[subIndex], products: allFileUpload },
                                        ...values.subCategories.slice(subIndex + 1),
                                    ],
                                });
                                toast.success("Image uploaded successfully");
                            })
                            .catch((err) => {
                                setLoading(false);
                                console.log(err);
                                toast.error("Image upload failed");
                            });
                    },
                    "base64"
                );
            }
        } else {
            toast.error("No files selected");
        }
    };
    
    const handleRemove = (public_id) => {
        setLoading(true);
        const { images } = values;
        axios
            .post(
                process.env.REACT_APP_API + "/removeimages",
                { public_id },
                { headers: { authtoken: user.token } }
            )
            .then((res) => {
                setLoading(false);
                let filterImages = images.filter((item) => item.public_id !== public_id);
                setValues({ ...values, images: filterImages });
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    return (
        <>
            <br />
            <span className="avatar-item">
                <Badge
                    onClick={() => handleRemove(values.subCategories[subIndex].products[productIndex].public_id)}
                    style={{ cursor: "pointer" }}
                    count="x"
                >
                    <Avatar
                        className="m-3"
                        src={values.subCategories[subIndex].products[productIndex].image}
                        shape="square"
                        size={120}
                    />
                </Badge>
            </span>

            <hr />
            <div className="form-group">
                <label className="btn btn-primary">
                    Choose File
                    <input
                        onChange={handleChangeFile}
                        className="form-control"
                        type="file"
                        hidden
                        multiple
                        accept="image/*"
                        name="file"
                    />
                </label>
            </div>
            <br />
        </>
    );
};

export default FileUpload;
