import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getCategory, updateCategory } from "../../../function/MunuItem";
import FileUpload from "./FileUpload"; // นำเข้า FileUpload component

const UpdateMenuCategory = () => {
    const { user } = useSelector((state) => ({ ...state }));
    const { id } = useParams();

    const [values, setValues] = useState({
        name: "",
        subCategories: [{ 
            name: "", 
            websiteLink: "", 
            products: [{ 
                name: "", 
                images: [], 
                description: "", 
                price: "", 
                discountedPrice: "", 
                sold: 0, 
                quantity: 0 
            }] 
        }],
    });

    useEffect(() => {
        loadCategory();
    }, []);

    const loadCategory = () => {
        getCategory(user.token, id)
            .then((res) => {
                setValues({
                    name: res.data.name,
                    subCategories: res.data.subCategories || [{ name: "", websiteLink: "", products: [{ name: "", images: [], description: "", price: "", discountedPrice: "", sold: 0, quantity: 0 }] }],
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error loading category");
            });
    };

    const handleChangeCategory = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const handleSubCategoryChange = (index, e) => {
        const newSubCategories = [...values.subCategories];
        newSubCategories[index].name = e.target.value;
        setValues({ ...values, subCategories: newSubCategories });
    };

    const handleWebsiteLinkChange = (index, e) => {
        const newSubCategories = [...values.subCategories];
        newSubCategories[index].websiteLink = e.target.value; // อัปเดตลิงก์เว็บไซต์
        setValues({ ...values, subCategories: newSubCategories });
    };

    const handleProductChange = (subCategoryIndex, productIndex, e) => {
        const newSubCategories = [...values.subCategories];
        newSubCategories[subCategoryIndex].products[productIndex][e.target.name] = e.target.value; // อัปเดตข้อมูลผลิตภัณฑ์
        setValues({ ...values, subCategories: newSubCategories });
    };

    const handleAddSubCategory = () => {
        setValues({ ...values, subCategories: [...values.subCategories, { name: "", websiteLink: "", products: [{ name: "", images: [], description: "", price: "", discountedPrice: "", sold: 0, quantity: 0 }] }] });
    };

    const handleAddProduct = (subCategoryIndex) => {
        const newSubCategories = [...values.subCategories];
        newSubCategories[subCategoryIndex].products.push({ name: "", images: [], description: "", price: "", discountedPrice: "", sold: 0, quantity: 0 }); // เพิ่มผลิตภัณฑ์ใหม่
        setValues({ ...values, subCategories: newSubCategories });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!values.name.trim()) {
            toast.error("กรุณากรอกชื่อหมวดหมู่สินค้า");
            return;
        }
        updateCategory(user.token, id, values)
            .then((res) => {
                console.log(res);
                toast.success("อัปเดต " + res.data.name + " เรียบร้อย");
            })
            .catch((err) => {
                console.log(err);
                toast.error("Error updating category");
            });
    };

    return (
        <div className="container">
            <h1>อัปเดตหมวดหมู่สินค้า</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>ชื่อหมวดหมู่สินค้า</label>
                    <input
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChangeCategory}
                        className="form-control"
                    />
                </div>
                <h4>รายการย่อย</h4>
                {values.subCategories.map((subCategory, subCategoryIndex) => (
                    <div key={subCategoryIndex}>
                        <div className="form-group">
                            <input
                                type="text"
                                name={`subCategory${subCategoryIndex}`}
                                value={subCategory.name}
                                onChange={(e) => handleSubCategoryChange(subCategoryIndex, e)}
                                className="form-control"
                                placeholder="ชื่อรายการย่อย"
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                name={`websiteLink${subCategoryIndex}`}
                                value={subCategory.websiteLink}
                                onChange={(e) => handleWebsiteLinkChange(subCategoryIndex, e)}
                                className="form-control"
                                placeholder="ลิงก์เว็บไซต์"
                            />
                        </div>
                        <h5>ผลิตภัณฑ์ในรายการย่อย</h5>
                        {subCategory.products.map((product, productIndex) => (
                            <div key={productIndex}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        value={product.name}
                                        onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                        className="form-control"
                                        placeholder="ชื่อผลิตภัณฑ์"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="description"
                                        value={product.description}
                                        onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                        className="form-control"
                                        placeholder="คำอธิบาย"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="number"
                                        name="price"
                                        value={product.price}
                                        onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                        className="form-control"
                                        placeholder="ราคา"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="number"
                                        name="discountedPrice"
                                        value={product.discountedPrice}
                                        onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                        className="form-control"
                                        placeholder="ราคาลด"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="number"
                                        name="sold"
                                        value={product.sold}
                                        onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                        className="form-control"
                                        placeholder="จำนวนที่ขายไป"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="number"
                                        name="quantity"
                                        value={product.quantity}
                                        onChange={(e) => handleProductChange(subCategoryIndex, productIndex, e)}
                                        className="form-control"
                                        placeholder="จำนวนสินค้า"
                                    />
                                </div>
                                {/* ใช้ FileUpload component สำหรับการอัปโหลดรูปภาพ */}
                                <FileUpload
                                    values={values}
                                    setValues={setValues}
                                    loading={false}
                                    setLoading={() => {}}
                                    subIndex={subCategoryIndex}
                                    productIndex={productIndex}
                                />
                            </div>
                        ))}
                        <button type="button" onClick={() => handleAddProduct(subCategoryIndex)} className="btn btn-outline-secondary">
                            เพิ่มผลิตภัณฑ์
                        </button>
                    </div>
                ))}
                <button type="button" onClick={handleAddSubCategory} className="btn btn-outline-secondary">
                    เพิ่มรายการย่อย
                </button>
                <button className="btn btn-outline-primary">บันทึกการเปลี่ยนแปลง</button>
            </form>
        </div>
    );
};

export default UpdateMenuCategory;
