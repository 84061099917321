import React from 'react';
import { Carousel, Row, Col, Button } from "react-bootstrap";
import ReactTypingEffect from "react-typing-effect"; // นำเข้า ReactTypingEffect
import "./Donate.css"; // สร้างไฟล์ CSS เพื่อตั้งค่าเงาขอบ

// import DonatePok1 from "../page/imageDonate/2.jpg";
import DonatePok2 from "../page/imageDonate/3.jpg";
import DonatePok3 from "../page/imageDonate/4.jpg";

const Donate = () => {
  return (
    <div className="container shadow-box mt-4 p-4"> {/* เพิ่ม class shadow-box */}
      <div className="text-center mb-4">
        <ReactTypingEffect
          text={["ร่วมแบ่งปันกับเรา"]}
          speed={100}
          eraseDelay={1000}
          className="typing-effect"
          style={{ fontSize: "24px", fontWeight: "bold" }} // กำหนดขนาดและรูปแบบตัวอักษร
        />
      </div>

      <div className="row justify-content-center align-items-center">
        <div className="col-6 col-md-5 mb-3">
          <Carousel interval={2000}>
            {/* <Carousel.Item>
              <img className="d-block w-100" src={DonatePok1} alt="DonatePok3" />
            </Carousel.Item> */}
            <Carousel.Item>
              <img className="d-block w-100" src={DonatePok2} alt="DonatePok2" />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={DonatePok3} alt="DonatePok3" />
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="col-6 col-md-5 text-center">
          ร่วมแบ่งปันกับเรา ได้ตามช่องทางนี้
        </div>
      </div>
    </div>
  );
};

export default Donate;
