// src/function/MunuItem.js
import axios from "axios";

export const getCategory = async (authtoken, id) => {
    const url = `${process.env.REACT_APP_API}/menu/${id}`;
    console.log("Fetching category from:", url); // แสดง URL ที่ถูกสร้าง
    return await axios.get(url, {
        headers: {
            authtoken,
        },
    });
};

export const updateCategory = async (authtoken, id, value) => {
    return await axios.put(process.env.REACT_APP_API + "/menu/" + id, value, {
        headers: {
            authtoken,
        },
    });
};

// ฟังก์ชันที่มีอยู่
export const createCategory = async (authtoken, value) => {
    return await axios.post(process.env.REACT_APP_API + "/menu", value, {
        headers: {
            authtoken,
        },
    });
};

export const listCategory = async (authtoken) => {
    return await axios.get(process.env.REACT_APP_API + "/menu", {
        headers: {
            authtoken,
        },
    });
};

export const deleteCategory = async (authtoken, id) => {
    return await axios.delete(process.env.REACT_APP_API + "/menu/" + id, {
        headers: {
            authtoken,
        },
    });
};

export const ReadCategory = async (authtoken, id) => {
    return await axios.get(process.env.REACT_APP_API + "/menu/" + id, {
        headers: {
            authtoken,
        },
    });
};


export const EditCategory = async (authtoken,id,value) => {
    return await axios.put(process.env.REACT_APP_API + "/menu/"+id,value ,{
      headers:{
        authtoken,
      }
    } );
  };   

  export const getSubCategoryProducts = async (authtoken, subId) => {
    return await axios.get(process.env.REACT_APP_API + "/menu/products/" + subId, {
        headers: {
            authtoken,
        },
    });
};

export const getProduct = async (authtoken, id) => {
    return await axios.get(process.env.REACT_APP_API + "/menu/product/" + id, {
        headers: {
            authtoken,
        },
    });
};