import React from 'react'
import { FaSpa, FaHeart, FaUserMd, FaHandsHelping, FaRegSun } from 'react-icons/fa';
const Product = () => {
  const categories = [
    { id: 1, icon: <FaSpa />, text: 'การบำบัดด้วยสปา' },
    { id: 2, icon: <FaHeart />, text: 'การดูแลสุขภาพ' },
    { id: 3, icon: <FaUserMd />, text: 'การรักษาแพทย์' },
    { id: 4, icon: <FaHandsHelping />, text: 'การฟื้นฟูสุขภาพ' },
    { id: 5, icon: <FaRegSun />, text: 'การบำบัดแสง' },
  ];

  return (
    <div className="container">
      <h1>หมวดหมู่สินค้า Product</h1>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        {categories.map(category => (
          <li key={category.id} style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
            <span style={{ marginRight: '10px' }}>{category.icon}</span>
            <span>{category.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Product
